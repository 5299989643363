import React, { useState, useEffect } from "react";
import Dashboard_layout from "../Layout/Dashboard_layout";
import Table from "react-bootstrap/Table";
import { Button, Col, Container, Form, FormLabel, Row, Modal } from "react-bootstrap";

import axiosMain from "../../http/axios/axios_main";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { moment } from "moment";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
// import {} from "react-router-dom";
import axiosNew from "../../http/axios/axios_new";

const CreateInvoice = () => {

    // const history = useHistory();

    const navigate = useNavigate();
    const { user, isAuthenticated } = useAuth();
    var converter = require('number-to-words');
    const { state } = useLocation("/dashboard");

    const { prescriptionid } = state;


    //   const { apointid } = state;


    const [indetail, setindetail] = useState(false);

    const [pname, setpname] = useState(false);
    const [mr, setmr] = useState(false);
    const [age, setage] = useState(false);
    const [doctor, setdoctor] = useState(false);
    const [center, setcenter] = useState(false);
    const [bill, setbill] = useState(false);
    const [date_patient, setdate_patient] = useState(false);
    const [company, setcompany] = useState(false);
    const [mobile, setmobile] = useState(false);
    const [showed, setShow] = useState(false);
    const [appointid, setappointid] = useState(false);

    const [tabledata, settabledata] = useState([]);

    const [discount_val, setdiscounts_val] = useState(0);

    const [couponcode, setcouponcode] = useState('');

    const [amount_type, setamount_type] = useState('');

    const [amount_cou, setamount_cou] = useState('');





    const [inputdata, setInputdata] = useState({
        coupon: "",
    });

    const formdata = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputdata({ ...inputdata, [name]: value });
    };

    //   const [appoin_type, setappoin_type] = useState(false);

    //   const [puid,setpuid]=useState();

    const getData = async () => {
        // const api = await axios.post("https://api.medyseva.com/api/vle/prescription/details", {
        const api = await axiosNew.post("/api/vle/prescription/details", {

            prescription_id: prescriptionid,
        });
        console.log(api, 'newinvoice_ho gya data pp');

        if (api.data.status == 1) {

            //   setpuid(api.data.data.appointment_data.puid);
            setpname(api.data.data.patient.name);
            setmr(api.data.data.patient.mr_number);
            setage(api.data.data.patient.age);

            setdoctor(api.data.data.prescription.user.name);
            setcenter(api.data.data.prescription.chamber.name);


            // setcompany(api.data.data.appointment_data.created_at);

            setbill(api.data.data.invoice_number);
            setdate_patient(api.data.data.appoinment.date);
            setmobile(api.data.data.patient.mobile);
            settabledata(api.data.data.pre_investigation);

            setappointid(api.data.data.appoinment.id);

            //   setappoin_type(api.data.data.appointment_data.appointment_type);
        }
    };
    useEffect(() => {
        getData();
    }, []);
    const deleteinves = async (data, index) => {

        let newdata = tabledata.filter((data, i) => i !== index);

        settabledata(newdata);
        // console.log(data,'yttttoooppp----------');
        const apisdelete = await axiosNew.post("/api/vle/prescription/pre-investigation-delete", {

            // const apisdelete = await axios.post("https://api.medyseva.com/api/vle/prescription/pre-investigation-delete", {
            pre_investigation_id: data.id,
        });
    }
    // let consultaionfees=appoin_type==1 ? 150 :500;
    let totalinv = 0;
    {
        tabledata &&
            tabledata.map((items, index) => {
                totalinv += items.price;
            })
    };
    // totalinv=totalinv + consultaionfees;
    const handlecreateinvoice = async () => {
        // console.log(data,'pid');
        try {
            console.log(user, 'user');
            console.log(prescriptionid, 'prescriptionid');
            console.log(totalinv, 'totalinv');
            console.log(appointid);
            // const apis = await axios.post("https://api.medyseva.com/api/vle/prescription/invoice-create", {
            const apis = await axiosNew.post("/api/vle/prescription/invoice-create", {
                vle_id: user.id,
                prescription_id: prescriptionid,
                amount: totalinv - discount_val,
                appoinment_id: appointid,
                invoice_number: bill,
                coupon_code: couponcode,
                amount_type: amount_type,
                coupon_amount: discount_val,
            });


            console.log(apis.data.msg, "data aa gaya oo");
            if (apis.data.status == 1) {
                toast.success("Invoice Created Successfully");
                navigate(-1);
            } else {

                toast.warn(apis.data.msg);

            }
            // window.location.reload();

            // history.goBack();
            // toast.success("Invoice Created Successfully");
        } catch (error) {
            console.log(error, "create-invoice");
        }
    };
    const handleTopup = async (data) => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    };


    let totalvaluestr = converter.toWords((+totalinv - discount_val));
    let tvstr = totalvaluestr.toUpperCase();



    const HandleSubmit = async () => {



        const formData = new FormData();

        formData.append("coupon_code", inputdata.coupon);



        let result = await axiosNew.post("/api/vle/invoice/coupon/verify", formData);

        console.log(result.data.data, 'rescoupom');

        if (result.data.status == 1) {

            if (result.data.data.amount_type == 'flat') {

                let updateamount = totalinv - result.data.data.amount;

                //  setconsulfee(updateamount);

                setdiscounts_val(result.data.data.amount);

                setcouponcode(result.data.data.coupon_code);
                setamount_type(result.data.data.amount_type);
                setShow(false);

                toast.success('Coupon successfully applied');


            } else if (result.data.data.amount_type == 'percentage') {



                let updateamount = (result.data.data.amount * totalinv) / 100;

                setdiscounts_val(updateamount);

                setcouponcode(result.data.data.coupon_code);
                setamount_type(result.data.data.amount_type);

                setShow(false);

                toast.success('Coupon successfully applied');
            }



        } else {

            toast.error(result.data.msg);

        }



    }
    return (
        <div>
            <Dashboard_layout>
                <ToastContainer />
                <div className="content-top-padding">
                    <div
                        style={{
                            border: "1px solid #ddd",
                            width: "900px",
                            margin: "0 auto",
                            "font-family": "sans-serif",
                            "margin-top": "20px",
                            padding: "30px 20px",
                        }}
                    >
                        <div style={{ padding: "30px 20px" }}>
                            <table style={{ width: "100%", padding: "20px 0" }}>
                                <tbody>
                                    <tr>
                                        <td width="20%" style={{ padding: "10px" }}>
                                         

                                            {user.country == "india" ? (
                                                <img
                                                className="img-fluid chamber-img1"
                                                src="assets\img\logo\medyseva\logo.png"
                                                />

                                            ) : (
                                                <img
                                                className="img-fluid chamber-img1"
                                                src="assets\img\logo\medyseva\logo.png"
                                                />
                                            )}
                                        </td>
                                        <td
                                            width="60%"
                                            style={{
                                                "-webkit-text-align": "center",
                                                "text-align": "center",
                                                padding: "10px",
                                            }}
                                        >
                                            <h5
                                                style={{
                                                    "margin-bottom": "5px",
                                                    "margin-top": "0",
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {prescriptionid}-- Medyseva Technologies Pvt. Ltd.,18/A, Electronic Complex, Pardesipura, Indore 452010  (M.P.)<br></br> Phone:7566975666<br></br>Mail To:info@medyseva.com.
                                            </h5>
                                            <h4
                                                style={{
                                                    "margin-bottom": "5px",
                                                    "margin-top": "0",
                                                    fontSize: "16px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                {/* manishapatidar1234@questglt.org */}
                                            </h4>
                                        </td>
                                        <td
                                            width="12%"
                                            style={{ "vertical-align": "bottom", padding: "10px" }}
                                        >
                                            <h5
                                                style={{
                                                    "margin-bottom": "5px",
                                                    "margin-top": "0",
                                                    "-webkit-text-align": "right",
                                                    "text-align": "right",
                                                    fontSize: "13px",
                                                    fontWeight: "600",
                                                }}
                                            >
                                                OPD Billing
                                            </h5>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="" style={{ borderTop: "1px solid #ddd" }}>
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr>
                                        <td width="50%" style={{ padding: "10px" }}>
                                            <table>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Patient Name:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>{pname}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        MR No.:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>{mr}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Gender/ Age:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>{age}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Ref. Doctor:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>{doctor}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Center Name:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>{center}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                        <td width="50%" style={{ padding: "10px" }}>
                                            <table>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Bill No.:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>{bill}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Date:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>{date_patient}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Ref. Company:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>SELF</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td
                                                        width="40%"
                                                        style={{
                                                            fontSize: "14px",
                                                            padding: "5px",
                                                            fontWeight: "600",
                                                        }}
                                                    >
                                                        Mobile No.:
                                                    </td>
                                                    <td
                                                        width="60%"
                                                        style={{ fontSize: "14px", padding: "5px" }}
                                                    >
                                                        <span>{mobile}</span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="for table-box">
                            <Table stripped bordered hover size="sm">
                                <thead style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                                    <tr>
                                        <th width="10%" style={{ color: "#000" }}>
                                            S.No
                                        </th>
                                        <th width="20%" style={{ color: "#000" }}>
                                            {" "}
                                            Service Name
                                        </th>
                                        <th width="20%" style={{ color: "#000" }}>
                                            QTY
                                        </th>
                                        <th width="20%" style={{ color: "#000" }}>
                                            {" "}
                                            Rate
                                        </th>
                                        <th width="15%" style={{ color: "#000" }}>
                                            Total Amount
                                        </th>
                                        <th width="20%" style={{ color: "#000" }}>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {/* <tr>
                          <td>1</td>
                          <td>Consultaion Fee</td>
                          <td>1</td>
                          <td>{appoin_type==1 ? '150' : '500'}</td>
                          <td>{appoin_type==1 ? '150' : '500'}</td>
                        </tr> */}
                                    {/* {invoicetable && invoicetable.map((items,index)=>{ */}
                                    {/* return ( */}

                                    {tabledata &&
                                        tabledata.map((items, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{items.name}</td>
                                                    <td>1</td>
                                                    <td>{items.price}</td>
                                                    <td>{1 * items.price}</td>
                                                    <td onClick={(e) => deleteinves(items, index)} style={{ color: 'blue', cursor: 'pointer' }}>Delete</td>

                                                </tr>
                                            );
                                        })}

                                    <tr>
                                        {/* <td>{index + 1}</td>
                    <td>{items.name}</td>
                    <td>{items.price ==null ?'0' :items.price}</td>
                    <td>{items.discount==null ? '0' : items.price}</td>
                    <td>{items.price ==null ?'0' :items.price}</td> */}
                                    </tr>
                                    {/* ) */}
                                    {/* })} */}
                                </tbody>
                            </Table>
                            <table
                                style={{
                                    width: "40%",
                                    marginRight: "0",
                                    marginLeft: "auto",
                                    fontSize: "14px",
                                    marginTop: "20px",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tbody style={{ borderBottom: "1px solid #eee" }}>
                                    <tr>
                                        <td style={{ padding: "5px" }}>
                                            <b>Discount</b>
                                        </td>
                                        <td style={{ padding: "5px" }}>:</td>
                                        <td style={{ padding: "5px" }}>{discount_val}</td>
                                    </tr>
                                    <tr>
                                        <div>
                                            <button
                                                style={{
                                                    backgroundColor: "#053479",
                                                    color: "#fff",
                                                    padding: "8px 10px",
                                                    fontSize: "14px",
                                                    borderRadius: "5px",
                                                    border: "none",

                                                }}
                                                onClick={(e) => handleTopup()}                                    >
                                                Apply Coupon
                                            </button>
                                        </div>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "5px" }}>
                                            <b>Total</b>
                                        </td>
                                        <td style={{ padding: "5px" }}>:</td>
                                        <td style={{ padding: "5px" }}>
                                            <b>Rs. {totalinv - discount_val}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "5px" }}>
                                            {/* <button
                        style={{
                          backgroundColor: "#053479",
                          color: "#fff",
                          padding: "8px 10px",
                          fontSize: "14px",
                          borderRadius: "5px",
                          border: "none",
                        }}
                      >
                        Apply Coupon
                      </button> */}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table
                                style={{
                                    width: "50%",
                                    fontSize: "14px",
                                    marginTop: "20px",
                                    borderCollapse: "collapse",
                                }}
                            >
                                <tbody>
                                    <tr>
                                        <td style={{ padding: "5px" }}>
                                            <b>Net Amount In Words</b>
                                        </td>
                                        <td style={{ padding: "5px" }}>:</td>
                                        <td style={{ padding: "5px" }}>
                                            <b>{tvstr}</b>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div
                                className=""
                                style={{ display: "flex", justifyContent: "space-between" }}
                            >


                                <div>
                                    <button
                                        style={{
                                            backgroundColor: "#053479",
                                            color: "#fff",
                                            padding: "8px 10px",
                                            fontSize: "14px",
                                            borderRadius: "5px",
                                            border: "none",

                                        }}
                                        onClick={(e) => handlecreateinvoice()}
                                    >
                                        Approve
                                    </button>
                                </div>


                                <div style={{ fontSize: "16px", fontWeight: "500" }}>
                                    <b>For Medyseva Technologies</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    className="appointment-modal"
                    show={showed}
                    onHide={handleClose}
                    animation={false}
                    centered
                >
                    <Modal.Header className="head-modal1" closeButton>
                        <Modal.Title>Coupon Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <div className="">

                                <Row>
                                    <div className="mb-4">
                                        <Form.Label className="bold-head">Coupon No.</Form.Label>
                                        <Form.Control
                                            placeholder="Enter Coupon no"
                                            // onChange={handleformdata}
                                            // value={inputdata.utr_no}
                                            value={inputdata.coupon}
                                            onChange={formdata}
                                            name="coupon"
                                        />
                                    </div>
                                </Row>

                                <Container>
                                    <div
                                        className="justify-content-center"
                                        style={{ display: "flex" }}
                                    >
                                        <Button
                                            className="btn btn-primary margin-btn"
                                            onClick={() => setShow(false)}
                                        >
                                            Close
                                        </Button>
                                        <Button
                                            id="entersubmit"
                                            className="btn btn-primary margin-btn"
                                            type="button"
                                            onClick={() => HandleSubmit()}
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Container>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </Dashboard_layout>
        </div>
    );
};

export default CreateInvoice;
